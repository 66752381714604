import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import Footer from "../../common/Footer";
import Hero from "../../components/project/Hero";
import ProjectCard from "../../components/project/ProjectCard";
import ScrollToTop from "react-scroll-to-top";
import axios from "axios";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  content: {
    marginBottom: "6rem",
    position: "relative",
  },
  main: {
    display: "flex",
    justifyContent: "center",
    // alignItems: "center",
    gap: "30px",
    padding: "0 10px",
    width: "1280px",
    margin: "0 auto",
  },
  sidebar: {
    flex: 1,
    // backgroundColor: "#fe5600",
    height: "600px",
    position: "sticky",
    top: "130px",
  },
  projectContainer: {
    flex: 3,
  },
  containerCard: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gap: "30px",
    justifyContent: "center",
    paddingBottom: "3rem",
  },
  titleContainer: {
    borderBottom: "1px solid",
    height: "80px",
    marginBottom: "20px",
    fontStyle: "italic",
    "& h2": {
      fontSize: "32px",
    },
    "& h4": {
      fontSize: "20px",
      fontWeight: "600",
      textAlign: "justify",
    },
  },
  subList: {
    width: "70%",
    margin: "0 0 auto auto",
    color: "#999",
    "& li": {
      fontWeight: "normal",
    },
  },
  list: {
    "& > li": {
      fontWeight: "bold",
      margin: "10px auto",
      fontSize: "14px",
      cursor: "pointer",
      "&:hover": {
        color: "#fe5600",
      },
    },
  },
  title: {
    textAlign: "center",
    margin: "70px auto 70px",
    fontSize: "30px",
    fontWeight: "normal",
  },
  "@media (max-width: 1024px)": {
    content: {
      marginBottom: "6rem",
      position: "relative",
    },
    main: {
      display: "flex",
      justifyContent: "center",
      // alignItems: "center",
      gap: "10px",
      padding: "0 10px",
      width: "100%",
      margin: "0 auto",
    },
    sidebar: {
      display: "none",
    },
    projectContainer: {
      flex: 3,
    },
    containerCard: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr",
      gap: "1rem",
      justifyContent: "center",
      paddingBottom: "0rem",
    },
    titleContainer: {
      display: "none",
    },
    subList: {
      width: "70%",
      margin: "0 0 auto auto",
      color: "#999",
      "& li": {
        fontWeight: "normal",
      },
    },
    list: {
      "& > li": {
        fontWeight: "bold",
        margin: "10px auto",
        fontSize: "14px",
        cursor: "pointer",
        "&:hover": {
          color: "#fe5600",
        },
      },
    },
    title: {
      // display: "none",
      fontSize: "24px",
    },
  },
  "@media (max-width: 640px)": {
    content: {
      marginBottom: "6rem",
      position: "relative",
    },
    main: {
      display: "flex",
      justifyContent: "center",
      // alignItems: "center",
      gap: "10px",
      padding: "0 10px",
      width: "100%",
      margin: "0 auto",
    },
    sidebar: {
      display: "none",
    },
    projectContainer: {
      flex: 3,
    },
    containerCard: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gap: "1rem",
      justifyContent: "center",
      paddingBottom: "0rem",
    },
    titleContainer: {
      display: "none",
    },
    subList: {
      width: "70%",
      margin: "0 0 auto auto",
      color: "#999",
      "& li": {
        fontWeight: "normal",
      },
    },
    list: {
      "& > li": {
        fontWeight: "bold",
        margin: "10px auto",
        fontSize: "14px",
        cursor: "pointer",
        "&:hover": {
          color: "#fe5600",
        },
      },
    },
    title: {
      // display: "none",
      fontSize: "20px",
      textAlign: "center",
      margin: "-20px auto 30px",
      fontWeight: "normal",
    },
  },
}));

const Projects = () => {
  const classes = useStyles();
  const [filter, setFilter] = useState("");
  const [search, setSearch] = useState("");
  const [genre, setGenre] = useState("");
  const [data, setData] = useState([]);
  // const [query, setQuery] = useState('');
  const lang = useSelector((state) => state.lang.lang);
  let [category, setcategory] = useState([]);
  useEffect(() => {
    axios.get("https://api.themoderntouch.co/wp-json/wp/v2/category-project").then((response) => {
      setcategory(response.data);
      // console.log(response.data)
    });
  }, []);

  const customSort = (a, b) => {
    const dateA = new Date(a.acf.date);
    const dateB = new Date(b.acf.date);
    if (dateA > dateB) return -1;
    else if (dateA < dateB) return 1;
    return 0;
  };
  useEffect(() => {
    const fetchProjects = async () => {

      const res = await axios.get(
        `https://api.themoderntouch.co/wp-json/wp/v2/project?per_page=60&acf_format=standard`
      );
      setData(res.data.filter(item => filter ? item.acf.category === filter : item).filter(item => item.title.rendered.includes(search)));


      // console.log(res.data);
    };
    // console.log(search);
    fetchProjects();
  }, [filter, search]);

  const renderCard = () => {
    return data.sort(customSort).map((project, key) => {
      // console.log(project.acf.date, project.title.rendered);
      return (
        <ProjectCard
          index={key}
          path={project.slug}
          key={key}
          imgProject={project?.acf.cover_image.toString()}
          timeProject={lang ? project.acf.city_vi : project.acf.city_en}
          styleProject={lang ? project.acf.style_vi : project.acf.style_en}
          nameProject={lang ? project?.acf.vi_name : project.title.rendered}
        />
      );
    });
  };

  return (
    <div>
      <div className={classes.content}>
        <Hero banner={data.filter(item => item.acf.set_banner === true)} />
        <div>
          <div
            className={classes.title}
            data-aos="fade-up"
            data-aos-easing="ease"
            data-aos-duration="1000"
          //  data-aos-delay={item.id * 200}
          >
            <p>
              {lang
                ? "KHÁM PHÁ CÁC DỰ ÁN CỦA CHÚNG TÔI"
                : "DISCOVER OUR FEATURED PROJECTS"}
            </p>
          </div>
          <div className={classes.main}>
            <div className={classes.sidebar}>
              <div
                className={classes.titleContainer}
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-duration="1000"
                data-aos-delay="300"
              >
                <h2>{lang ? "DANH MỤC DỰ ÁN" : "OUR PROJECTS"}</h2>
              </div>
              <div>
                <ul className={classes.list}>
                  {category.map((item, index) => {
                    return (
                      <li
                        data-aos="fade-up"
                        data-aos-easing="ease"
                        data-aos-duration="1000"
                        data-aos-delay={`${400 + index * 100}`}
                        onClick={() => {
                          setFilter(`${index + 1}`);
                        }}
                        style={
                          filter === `${index + 1}`
                            ? { color: "#fe5600" }
                            : null
                        }
                        key={index}
                        dangerouslySetInnerHTML={{ __html: item?.name }}
                      >
                        {/* {lang ? item.acf.viname : item.acf.name} */}
                      </li>
                    );
                  })}

                  <li
                    data-aos="fade-up"
                    data-aos-easing="ease"
                    data-aos-duration="1000"
                    data-aos-delay="1000"
                    onClick={() => {
                      setFilter('');
                    }}
                    style={filter === "" ? { color: "#fe5600" } : null}
                  >
                    {lang ? "Tất cả dự án" : "All Projects"}
                  </li>
                </ul>
              </div>
              <div
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-duration="1000"
                data-aos-delay="1000"
              >
                <input
                  style={{
                    width: "100%",
                    border: "1px solid #ccc",
                    borderRadius: "20px",
                    padding: "4px 30px",
                    marginTop: "10px",
                    fontStyle: "italic",
                  }}
                  type="text"
                  placeholder={lang ? "Tìm kiếm theo tên" : "Search ..."}
                  onChange={(e) => setSearch(e.target.value.toUpperCase())}
                />
              </div>
            </div>
            <div className={classes.projectContainer}>
              <div
                className={classes.titleContainer}
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-duration="1000"
                data-aos-delay="400"
              >
                <h4>
                  {lang
                    ? " Một vài dự án tiêu biểu mà chúng tôi đã và đang thực hiện trên hành trình theo đuổi sứ mệnh kiến tạo chất lượng cuộc sống cho mỗi khách hàng."
                    : "Some selected projects that we have and are fulfilling on our journey to pursue the mission of building quality of life for each client."}
                </h4>
              </div>
              <div className={classes.containerCard}>{renderCard()}</div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
      <ScrollToTop smooth color="#fe5600" style={{ borderRadius: "50%" }} />
    </div>
  );
};

export default Projects;
