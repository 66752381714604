import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";

import Footer from "../../common/Footer";
import PopUp from "../../components/project/PopUp";
import HeroDetail from "../../components/project/HeroDetail";
import GalleryImages from "../../components/project/GalleryImages";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  container: {
    fontSize: "16px",
    display: 'flex',
    flexDirection: 'column',
    gap: '60px',
    "& th": {
      fontWeight: "normal",
      textAlign: "left",
    },
  },
  gallery: {
    marginBottom: "2rem",
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridGap: "10px",
    margin: "auto",
    width: "90vw",
    marginBottom: "4rem"
  },
}));
const DetailProject = () => {
  const [openPopUp, closePopUp] = useState(false);
  const [indexImage, setIndexImg] = useState(null);
  let { id } = useParams();
  // id = id.split("-");
  // id = id[id.length - 1];
  const lang = useSelector((state) => state.lang.lang);
  const handleClosePopUp = () => {
    closePopUp(false);
  };
  const handleOpenPopUp = () => {
    closePopUp(true);
  };
  const handleSelectImg = (index) => {
    setIndexImg(index);
  };
  const classes = useStyles();
  const [project, setProject] = useState(null);
  useEffect(() => {
    const fetchProjects = async () => {
      const res = await axios.get(
        `https://api.themoderntouch.co/wp-json/wp/v2/project?slug=${id}&acf_format=standard`
      );
      setProject(res.data[0]);
    };
    fetchProjects();
  }, [id]);
  // console.log(project?.images);

  return (
    <div className={classes.container}>
      <HeroDetail project={project} />
      {/* {project?.images && ( */}
      {/* <div> */}

      <div className={classes.grid}>
        {
          project?.acf.photo_gallery.gallery_image[1].map((item, index) => {
            return (
              <div key={index} onClick={() => {
                handleSelectImg(index)
                handleOpenPopUp()
              }} style={{ backgroundImage: `url('${item.full_image_url}')`, cursor: 'pointer', backgroundSize: 'cover', width: '100%', aspectRatio: '16/9' }} />
            )
          })
        }
      </div>
      {/* <GalleryImages margin={"2rem"} photos={project?.acf.photo_gallery.gallery_image[1]} /> */}
      {/* </div> */}
      {/* )} */}
      {openPopUp && (
        <PopUp
          imageUrls={project?.acf.photo_gallery.gallery_image[1]}
          indexImage={indexImage}
          handleClosePopUp={handleClosePopUp}
        />
      )}
      <Footer />
    </div>
  );
};

export default DetailProject;
