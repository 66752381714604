import { makeStyles } from "@mui/styles";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
// import { Link } from 'react-router-dom's
import Slider from "react-slick";
import { convertUrl } from "../../utils/imgUrl";
// import projectList from '../../utils/project.json'

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "calc(100vh - 124px)",
    position: "relative",
  },
  img: {
    width: "100%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
  },
  buttonContainer: {
    position: "absolute",
    bottom: "100px",
    left: "50%",
    transform: "translateX(-50%)",
    color: "white",
    textAlign: "center",
    fontFamily: "Times New Roman",
    "& p": {
      margin: "20px auto",
      fontSize: "16px",
      fontStyle: "italic",
    },
    "& h2": {
      minWidth: '1200px',
      fontSize: "60px",
    },
  },
  button: {
    border: "1px solid white",
    color: "white",
    borderRadius: "20px",
    padding: "2px 30px",
  },
  "@media (max-width: 640px)": {
    container: {
      height: "300px",
      position: "relative",
      width: "100vw",
    },
    img: {
      width: "100%",
    },
    buttonContainer: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%)",
      color: "white",
      textAlign: "center",
      fontFamily: "Times New Roman",
      "& p": {
        display: "none",
      },
      "& h2": {
        fontSize: "20px",
        marginBottom: "10px",
      },
    },
    button: {
      border: "1px solid white",
      color: "white",
      borderRadius: "20px",
      padding: "2px 10px",
      fontStyle: "italic",
    },
    slide: {
      height: "300px",
      marginTop: "-3rem",
    },
  },
}));

const Hero = ({ banner }) => {
  const [select, setSelect] = useState(null);
  // const [banner, setBanner] = useState([]);
  const classes = useStyles();
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,
    // pauseOnHover: false
    // draggable: false,
    dots: false,
  };
  const handleReadMore = (index) => {
    setSelect(index);
  };
  // useEffect(() => {
  //   const fetchProjects = async () => {
  //     const res = await axios.get(
  //       "http://62.72.56.96:8000/v1/project/banner"
  //     );
  //     setBanner(res.data);
  //     // console.log(res.data);
  //   };
  //   fetchProjects();
  //   // console.log(search);
  // }, []);
  const lang = useSelector((state) => state.lang.lang);
  const titleConvert = (title) => {
    let output = title.toLowerCase();
    output = output.replace(/[^\w ]+/g, "");
    output = output.replace(/ +/g, "-");
    return output;
  };

  // const bannerList = [banner1, banner2, banner3, banner4, banner5, banner6]
  return (
    <Slider {...settings} className={classes.slide}>
      {banner.map((item, index) => {
        return (
          <div key={index} className={classes.container}>
            <img className={classes.img} src={item.acf.banner_image} alt="banner" />
            <div className={classes.buttonContainer}>
              <h2 className="tracking-[6px]" dangerouslySetInnerHTML={{ __html: lang ? item?.acf.vi_name : item.title.rendered }}></h2>
              {/* <p dangerouslySetInnerHTML={{ __html: item?.excerpt?.rendered }}> */}

              {/* </p> */}
              <Link
                className={classes.button}
                to={item.slug}
              >
                {lang ? "Xem thêm" : "Read More"}
              </Link>
            </div>
          </div>
        );
      })}

    </Slider >
  );
};

export default Hero;
